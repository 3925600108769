<template>
  <app-modal
    :title="$t('dam.dam_generic_info')"
    modalSize=""
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <h4>{{ $t('site.enabled_sites') }}</h4>
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th>{{ $t('site.title') }}</th>
          <th>{{ $t('site.name') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(site, index) in getAllEnabledSites" :key="`item-${index}`">
          <td>{{ site.title }}</td>
          <td>{{ site.name }}</td>
        </tr>
        </tbody>
      </table>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'

export default {
  name: 'DamGenericInfoModal',
  components: {
    appModal: Modal
  },
  computed: {
    getAllEnabledSites () {
      return this.$store.getters['site/all'].filter(site => site.enabled === true)
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .asset-info-table {
    font-size: 14px;
    width: auto;
    height: auto;
  }
</style>
