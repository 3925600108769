<template>
  <Modal disable-header >
    <template slot="body">
      <ModuleForm
        :module-name="$t('dam.asset_info')"
        :show-header-info="false"
      >
        <template #form-title>
          <IconEdit class="form-icon" />
          {{ $t('dam.metadata_bulk_edit') }}
        </template>
        <template #header-buttons>
          <AssetEditForm
            :asset="asset"
            @close="$emit('close')"
            @post-update="$emit('post-update')"
            :bulk-edit-assets-ids="assetsIds"
            :changed-inputs="changedInputs"
            bulk-edit
            header
          />
          <button
            type="button"
            class="close"
            @click="$emit('close')"
          >
            ×
          </button>
        </template>
        <template #left-column>
          <AssetEditForm :asset="asset" :changed-inputs="changedInputs" bulk-edit left />
        </template>
        <template #right-column>
          <AssetEditForm :asset="asset" :changed-inputs="changedInputs" bulk-edit right />
        </template>
      </ModuleForm>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import ModuleForm from '@/components/shared/ModuleForm'
import AssetEditForm from '@/components/dam/assetDetail/AssetEditForm'
import IconEdit from '@/assets/img/svg/edit.svg?inline'

export default {
  name: 'AssetBulkMetadataEditModal',
  props: {
    assetsIds: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      asset: { siteLocks: [] },
      changedInputs: {}
    }
  },
  components: {
    IconEdit,
    AssetEditForm,
    ModuleForm,
    Modal
  }
}
</script>

<style lang="scss" scoped>
.close {
  margin-left: 1rem;
}
.form-icon {
  @include size(20px);
  fill: #9DAAC4;
}
</style>
