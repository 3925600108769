<template>
  <TooltipSlot
    :title="tooltip"
    class="abstract-data-table__th__tooltip"
  >
    <button
      class="btn-article-preview"
      type="button"
      :title="tooltip ? '' : $t('article.preview.article_preview')"
      :disabled="disabled"
      @click="preview"
    >
      <span class="btn-article-preview__content">
        <IconPreview class="btn-article-preview__icon" />
      </span>
    </button>
    <template #title>
      {{ tooltip }}
      <span class="btn-article-preview__content">
        <IconPreview class="btn-article-preview__icon" />
      </span>
    </template>
  </TooltipSlot>
</template>

<script>
import moment from 'moment'
import TooltipSlot from '@/components/tooltip/TooltipSlot'
import IconPreview from '@/assets/img/svg/preview.svg?inline'
import { mapGetters } from 'vuex'
import ArticlePreview from '@/components/mixins/ArticlePreview'
import { twoWeeksFromNowUtcFormat } from '@/services/DateTimeService'

export default {
  name: 'BtnArticlePreview',
  mixins: [ArticlePreview],
  props: {
    article: {
      type: Object
    },
    saveBeforePreview: {
      type: Boolean,
      default: false
    },
    saveCallback: {
      type: Function,
      default: () => {}
    },
    disableOnExpired: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TooltipSlot,
    IconPreview
  },
  computed: {
    ...mapGetters(['vlm']),
    tooltip () {
      return this.disabled ? this.$t('article.preview.preview_expired') : ''
    },
    disabled () {
      const { previewExpiration } = this.article.setting
      const previewIsExpired = previewExpiration && moment().isAfter(previewExpiration)
      return this.disableOnExpired && previewIsExpired
    }
  },
  methods: {
    async preview () {
      const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
      if (this.saveBeforePreview) {
        this.article.setting.previewExpiration = twoWeeksFromNowUtcFormat()
        await this.saveCallback()
      }
      if (isFirefox) {
        await new Promise(resolve => setTimeout(resolve, 50))
      }
      window.open(this.getPreviewUrl())
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-article-preview {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    &__content {
      background: #fff;
      width: rem(30px);
      height: rem(30px);
      border-radius: rem(4px);
      border: 1px solid #D1DBE4;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      &:hover {
        border: 1px solid #6599FE;
        .btn-article-preview__icon {
          fill: #6599FE;
        }
      }
    }
    &:disabled {
      pointer-events: none;
      opacity: .65;
    }
    &__icon {
      width: rem(17px);
      height: auto;
      fill: #9DAAC4;
    }
  }
</style>
