<template>
  <div style="margin-bottom: 15px; border-bottom: 1px solid #e9ecef">
    <app-preloader v-if="callingAPI"></app-preloader>
    <div v-if="callingAPI === false && articles.length === 0" class="m-t-10">
      <p class="alert alert-info">
        {{ $t('result_not_found') }}
      </p>
    </div>
    <div class="table-responsive" v-if="articles.length > 0">
      <table id="list-table" class="table table-striped">
        <thead>
        <tr>
          <th width="20">
            {{ $t("article.list.type") }}
          </th>
          <th>
            {{ $t("article.list.title") }}
          </th>
          <th width="150" class="hidden-sm-down">
            {{ $t("article.list.main_rubric") }} <br> <small>{{ $t("article.list.site") }}</small>
          </th>
          <th width="150" class="hidden-sm-down">{{ $t("article.list.author") }}</th>
          <th width="210" class="hidden-sm-down">
            {{ $t("article.list.order_date") }}<br>
            <small>{{ $t("article.list.last_modified") }}</small>
          </th>
          <th width="130">
            {{ $t("article.list.status") }}
          </th>
          <th width="192">
            {{ $t("actions") }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="article in articles" v-bind:key="article.id">
          <td>
            <div class="text-left article-types">
                                              <span v-if="article.type === 'article'" :title="$t('article.internal')">
                                                  <i class="fas fa-newspaper"></i>
                                              </span>
              <span v-if="article.type === 'external'" :title="$t('article.external')">
                                                  <i class="fas fa-external-link-alt"></i>
                                              </span>
              <span v-if="article.type === 'video'" :title="$t('article.video')">
                                                  <i class="fas fa-video"></i>
                                              </span>
              <span v-if="article.type === 'photostory'" :title="$t('article.photostory')">
                                                  <i class="fas fa-images"></i>
                                              </span>
              <span v-if="article.type === 'quiz'" :title="$t('article.quiz')">
                                                  <i class="fas fa-question-circle"></i>
                                              </span>
              <span v-if="article.type === 'recipe'" :title="$t('article.recipe')">
                                                  <i class="fas fa-utensils"></i>
                                              </span>
              <span v-if="article.type === 'test'" :title="$t('article.test')">
                                                  <i class="far fa-clipboard"></i>
                                              </span>
              <span v-if="article.type === 'longform'" :title="$t('article.longform')">
                                                  <i class="far fa-window-maximize"></i>
                                              </span>
              <span v-if="article.type === 'disease'" :title="$t('article.article_type.disease')">
                                                  <i class="fas fa-stethoscope"></i>
                                              </span>
              <span v-if="article.type === 'encyclopedy'" :title="$t('article.article_type.encyclopedy')">
                                                  <i class="fas fa-book"></i>
                                              </span>
            </div>
          </td>
          <td style="font-size:15px" class="cropTitle">
                                          <span
                                            v-if="article.setting.status === 'draft'"
                                            v-html="getActivityByDocId(article.documentId)"
                                          >
                                          </span>
            {{ article.field.title }}
          </td>
          <td class="hidden-sm-down">
            <strong>{{ rubricNameById(article.mainRubric) }}</strong><br>
            <span>
                                              {{ siteNameById(article.site) }}
                                          </span>
          </td>
          <td class="hidden-sm-down">
            <template v-for="(author, index) in article.authors">
              <TableArticleListAuthor
                :key="author + index"
                :index="index"
                :author="author"
                :article="article"
              />
            </template>
          </td>
          <td class="hidden-sm-down">
            <strong style="font-weight: 600">{{ article.orderDate | prettyDateTime }}</strong><br>
            <small v-if="article.draftModifiedAt">
              {{ article.draftModifiedAt | prettyDateTime }} {{ userNameById(article.draftModifiedBy) }}
            </small>
            <small v-if="!article.draftModifiedAt">
              {{ article.firstPublishedDate | prettyDateTime }} {{ userNameById(article.createdBy) }}
            </small>
          </td>
          <td>
            <app-article-status :article="article"></app-article-status>
          </td>
          <td>
            <app-article-preview-button
              :article="article"
              data-test="article_list_btn_preview"
            >
            </app-article-preview-button>
            <router-link
              tag="a"
              class="btn btn-default btn-sm"
              :to="{ name: 'article_detail', params: { id: article.id }}"
              data-test="article_list_btn_detail"
            >
              <i class="fa fa-info"></i>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ArticleStatus from '@/components/article/ArticleStatus'
import BtnArticlePreview from '@/components/buttons/BtnArticlePreview'
import CoreApi from '@/api/core'
import UserMixin from '../mixins/User'
import Preloader from '../preloader/Preloader'
import ArticleList from '@/components/mixins/ArticleList'
import TableArticleListAuthor from '@/components/table/TableArticleListAuthor'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'AssetArticleReference',
  mixins: [UserMixin, ArticleList],
  props: {
    asset: {
      type: Object
    }
  },
  components: {
    appArticleStatus: ArticleStatus,
    appArticlePreviewButton: BtnArticlePreview,
    appPreloader: Preloader,
    TableArticleListAuthor
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  data: function () {
    return {
      articles: []
    }
  },
  methods: {
    async getArticleReference (asset) {
      try {
        this.$store.commit('TOGGLE_LOADING')
        await CoreApi().get('/image/image-references/' + asset.entityUuid).then(response => {
          this.articles = response.data
        })
      } catch (error) {
        this.articles = []
        NotifyService.setErrorMessage(error)
      } finally {
        this.$store.commit('TOGGLE_LOADING')
      }
    }
  },
  mounted () {
    this.getArticleReference(this.asset)
  }
}
</script>

<style>
.cropTitle {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
}
</style>
