<template>
  <Modal disable-header >
    <template slot="body">
      <ModuleForm
        :module-name="assetInfoText"
        :show-header-info="false"
        :tabs="tabs"
        @tab-change="(index) => tabIndex = index"
      >
        <template #form-title>
          <IconInfo class="form-icon" />
          {{ assetInfoText }}
        </template>
        <template #tab-title="{ tab, index }">
          <component
            :is="getIconComponentName(tab)"
            class="tab-icon"
            :class="{ 'tab-active': index === tabIndex }"
          />
          {{ tab.name }}
        </template>
        <template #header-buttons>
          <AssetInfoForm
            v-show="tabIndex === 0"
            :asset="asset"
            @close="$emit('close')"
            @post-update="$emit('post-update', $event)"
            header
          />
          <AssetEditForm
            v-show="tabIndex === 1"
            :asset="asset"
            :changed-inputs="changedInputs"
            @close="$emit('close')"
            @post-update="$emit('post-update', $event)"
            @touch-validation="$refs.leftAssetEditForm.$v.$touch(); $refs.rightAssetEditForm.$v.$touch()"
            header
          />
          <button
            type="button"
            class="close"
            @click="$emit('close')"
          >
            ×
          </button>
        </template>
      <template #form-content>
        <div v-show="tabIndex !== 2" class="form-content">
          <div class="form-content__left">
            <AssetInfoForm
              v-show="tabIndex === 0"
              :asset="asset"
              @close="$emit('close')"
              left
            />
            <AssetEditForm
              ref="leftAssetEditForm"
              v-show="tabIndex === 1"
              :asset="asset"
              @close="$emit('close')"
              :changed-inputs="changedInputs"
              left
            />
          </div>
          <div class="form-content__separator">
            <div class="form-content__separator__line"/>
          </div>
          <div class="form-content__right">
            <AssetInfoForm
              class="info-form-right"
              v-show="tabIndex === 0"
              :asset="asset"
              @close="$emit('close')"
              right
            />
            <AssetEditForm
              ref="rightAssetEditForm"
              v-show="tabIndex === 1"
              :asset="asset"
              @close="$emit('close')"
              :changed-inputs="changedInputs"
              right
            />
          </div>
        </div>
        </template>
      <template v-if="tabIndex === 0 && asset.album" #form-footer>
        <div class="album-title">
          <span>{{ $t('dam.photo_album') }}:</span>
          <span class="album-title__name"> {{ asset.album }} </span>
        </div>
        <div class="horizontal-separator"></div>
        <Gallery
          class="gallery asset-album-gallery"
          :disabled="true"
          :gallery="asset.albumGallery ? asset.albumGallery : []"
          :show-hero-button="false"
          :show-body-button="false"
          parent-store-name="dam"
          small-paddings
        />
      </template>
      </ModuleForm>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/shared/Modal'
import ModuleForm from '@/components/shared/ModuleForm'
import AssetInfoForm from '@/components/dam/assetDetail/AssetInfoForm'
import AssetEditForm from '@/components/dam/assetDetail/AssetEditForm'
import Gallery from '@/components/article/ArticleGallery'
import IconInfo from '@/assets/img/svg/info.svg?inline'
import IconFile from '@/assets/img/svg/file.svg?inline'
import IconEdit from '@/assets/img/svg/edit.svg?inline'
import IconCrop from '@/assets/img/svg/crop.svg?inline'

export default {
  name: 'AssetDetailModal',
  props: {
    asset: {
      type: Object
    }
  },
  components: {
    IconInfo,
    IconFile,
    IconEdit,
    IconCrop,
    AssetEditForm,
    AssetInfoForm,
    ModuleForm,
    Gallery,
    Modal
  },
  computed: {
    ...mapGetters('dam', [
      'isPdf'
    ]),
    assetInfoText () {
      return this.isPdf ? this.$t('dam.asset_pdf_info') : this.$t('dam.asset_image_info')
    },
    tabs () {
      return [
        { name: this.$t('dam.info_modal_header'), icon: 'file' },
        { name: this.$t('dam.update_asset_metadata'), icon: 'edit' }
      ]
    }
  },
  data () {
    return {
      tabIndex: 0,
      changedInputs: {}
    }
  },
  methods: {
    getIconComponentName (tab) {
      const iconName = tab.icon.charAt(0).toUpperCase() + tab.icon.slice(1)
      return `Icon${iconName}`
    }
  }
}
</script>

<style lang="scss">
.form-header__tabs-included {
  @include bp(0 10) {
    display: flex;
    flex-direction: column;
    .form-tabs {
      margin-bottom: 20px;
    }
  }
}
</style>
<style lang="scss" scoped>
.close {
  margin-left: 1rem;
}
.asset-crop {
  justify-content: center;
}
.form-icon {
  @include size(20px);
  fill: #9DAAC4;
}
.tab-icon {
  @include size(16px);
  fill: #9DAAC4;
  padding-top: rem(3px);
}
.tab-active {
  fill: #6599FE;
}
.info-form-right {
  max-width: 99%;
}

.asset-album-gallery {
  max-height: calc(100vh / 4);
  overflow: scroll;
}

.horizontal-separator {
  width: 98%;
  height: rem(1px);
  margin: rem(10px);
  background: #d1dbe4;
}

.album-title {
  @include font(400 16px "Roboto");
  color: #9AA4B7;
  margin-left: rem(10px);
  &__name {
    @include font(700 16px "Roboto");
  }
}
</style>
