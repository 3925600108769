<template>
  <section>
    <div class="dam-filter">
      <DamFilter :pagination="true" @set-media="addMedia" />
    </div>
    <div class="drop-zone" id="drop-zone">
      <p class="text-center">
        <span>{{ $t('dam.drop_files_here') }}</span>
      </p>
    </div>
    <div class="dam-buttons" id="pagination-top-scroll-to">
      <div>
        <button
          @click="showMetadata"
          class="dam-button"
          :class="{ 'dam-button--active': showMeta }"
        >
          {{ $t('dam.display_type_full') }}
        </button>
        <button
          @click="showMetadata"
          class="dam-button"
          :class="{ 'dam-button--active': !showMeta }"
        >
          {{ $t('dam.display_type_compact') }}
        </button>
        <button
          class="dam-button"
          @click="showDamGenericInfoModal"
        >
          <i class="fas fa-info-circle"></i>
        </button>
      </div>
      <ButtonBulkActions
        :on-click="toggleSelectionMode"
        :is-enabled="selectionMode"
        :label="$t('buttons.bulk_actions')"
        color-enabled
        small
      />
    </div>
    <div
      class="dam-buttons"
      v-if="selectionMode"
    >
      <div class="selected_images_count">
        {{ $t(isPdf ? 'count' : 'dam.selected_photos_count') }}: {{ selectedCount }} / {{ maxCountOfSelectedAssets }}
      </div>
      <div>
        <button
          class="dam-button"
          :class="{ 'dam-button--disabled':  selectedAssetsArray.length === 0 }"
          :disabled="selectedAssetsArray.length === 0"
          @click="cancelSelection()"
        >
            <i class="fas fa-times"></i>
            {{ $t('buttons.reset_selection') }}
        </button>
        <button
          class="dam-button"
          :class="{ 'dam-button--disabled':  selectedAssetsArray.length === 0 }"
          :disabled="selectedAssetsArray.length === 0"
          @click="showAssetBulkMetadataEditModal"
        >
            <i class="fas fa-edit"></i>
            {{ $t('dam.metadata_bulk_edit') }}
        </button>
        <button
          v-if="downloading"
          class="dam-button"
          disabled
        >
            <i class="fas fa-download"></i>
            <span class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
            />
            {{ $t('notify.loading') }}
        </button>
        <button
          v-else
          target="_blank"
          class="dam-button"
          :class="{ 'dam-button--disabled':  selectedAssetsIds.length === 0 }"
          :disabled="selectedAssetsIds.length === 0"
          @click="downloadAssets(selectedAssetsIds)"
        >
            <i class="fas fa-download"></i>
            {{ $t('media.button_download_zip') }}
        </button>
        <ModuleFormButton
          v-if="isDeleteButtonVisible"
          class="delete-button"
          :class="{ 'dam-button--disabled':  selectedAssetsArray.length === 0 }"
          icon="delete"
          :disabled="selectedAssetsArray.length === 0"
          @click="showAssetDeleteMoreModal"
        />
      </div>
    </div>
    <Pagination2
      class="dam-pagination"
      :current-page="page"
      :current-limit="limit"
      :page-count="pageCount"
      :total-count="totalCount"
      :default-limit="LIMIT"
      :page-change-handler="pageChanged"
      :limit-change-handler="limitChanged"
      :disabled="Boolean(animatePageShift)"
    />
    <Preloader class="preloader" v-show="callingAPI && !animatePageShift" />
    <div class="pages">
      <!-- render 'assets' for 'prevPage' and 'nextPage' only during animation
           this fixes problem with 'display: flex' on the last page
           when there are less items
       -->
      <DamListTable
        :class="`prev-page prev-page-shift-${animatePageShift}`"
        :assets="animatePageShift ? prevPage : []"
        :selected-assets="selectedAssets"
        :selection-mode="selectionMode"
        :show-meta="showMeta"
      />
      <DamListTable
        :class="`current-page current-page-shift-${animatePageShift}`"
        :assets="currentPage"
        :selected-assets="selectedAssets"
        :selection-mode="selectionMode"
        :show-meta="showMeta"
        :max-count-of-selected-assets="maxCountOfSelectedAssets"
        @selected-assets-change="selectedAssets = $event"
        data-test="dam-media-list"
      />
      <DamListTable
        :class="`next-page next-page-shift-${animatePageShift}`"
        :assets="animatePageShift ? nextPage : []"
        :selected-assets="selectedAssets"
        :selection-mode="selectionMode"
        :show-meta="showMeta"
      />
    </div>
    <Pagination2
      class="dam-pagination"
      :current-page="page"
      :current-limit="limit"
      :page-count="pageCount"
      :total-count="totalCount"
      :default-limit="LIMIT"
      :page-change-handler="pageNumber => pageChanged(pageNumber, true)"
      :limit-change-handler="limitChanged"
      :disabled="Boolean(animatePageShift)"
    />
    <AssetBulkMetadataEditModal
      v-if="assetBulkMetadataEditModal"
      :assets-ids="selectedAssetsIds"
      @close="closeAssetBulkMetadataEditModal"
      @post-update="searchAssets"
    />
    <app-asset-delete-more-modal
      v-if="assetDeleteMoreModal"
      :assets="selectedAssetsArray"
      @post-delete="searchAssets"
      @close="closeAssetDeleteMoreModal"
    >
    </app-asset-delete-more-modal>
    <app-dam-generic-info-modal
      v-if="damGenericInfoModal"
      @close="closeDamGenericInfoModal"
    >
    </app-dam-generic-info-modal>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MediaMixin from '@/components/mixins/Media'
import Preloader from '@/components/preloader/Preloader'
import DamFilter from './DamFilter'
import AssetDeleteMoreModal from './AssetDeleteMoreModal'
import DamGenericInfoModal from './DamGenericInfoModal'
import PermissionService from '@/services/PermissionService'
import ButtonBulkActions from '@/components/buttons/BtnArticleShowAdvancedFilter'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import Pagination2 from '@/components/Pagination2'
import DamListTable from '@/components/dam/DamListTable'
import AssetBulkMetadataEditModal from '@/components/dam/assetDetail/AssetBulkMetadataEditModal'

export default {
  name: 'DamList',
  mixins: [MediaMixin],
  props: {
    isPdf: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      assetDeleteMoreModal: false,
      assetBulkMetadataEditModal: false,
      selectedAssets: {},
      showMeta: true,
      selectionMode: false, // needed to be named 'selectionMode' for methods from MediaMixin to work
      damGenericInfoModal: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.DAM_PERMISSIONS,
      animatePageShift: ''
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('dam', [
      'prevPage',
      'currentPage',
      'nextPage',
      'limit',
      'maxCreatedAt',
      'totalCount',
      'page'
    ]),
    LIMIT () {
      return this.isPdf ? 20 : 36
    },
    maxCountOfSelectedAssets () {
      return this.isPdf ? 12 : 50
    },
    isUploadButtonVisible () {
      return this.hasPermission(this.requiredPermissions.uploadButton)
    },
    isDeleteButtonVisible () {
      return this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    pageCount () {
      return Math.ceil(this.totalCount / this.limit)
    },
    selectedCount () {
      return Object.keys(this.selectedAssets).length ?? 0
    },
    selectedAssetsArray () {
      return Object.keys(this.selectedAssets).map(key => this.selectedAssets[key])
    },
    selectedAssetsIds () {
      return Object.keys(this.selectedAssets).map(key => this.selectedAssets[key].entityUuid)
    }
  },
  components: {
    AssetBulkMetadataEditModal,
    DamListTable,
    Pagination2,
    ModuleFormButton,
    Preloader,
    DamFilter,
    appAssetDeleteMoreModal: AssetDeleteMoreModal,
    appDamGenericInfoModal: DamGenericInfoModal,
    ButtonBulkActions
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    toggleSelectionMode () {
      this.selectionMode = !this.selectionMode
      this.selectedAssets = {}
    },
    searchAssets () {
      this.$store.dispatch('dam/fetchPage')
    },
    async fetchPage (page, scrollToTop) {
      if (scrollToTop) {
        document.getElementById('pagination-top-scroll-to').scrollIntoView({ behavior: 'smooth' })
      }
      this.$store.commit('dam/setPage', page)
      await this.$store.dispatch('dam/fetchPage')
      this.animatePageShift = ''
    },
    pageChanged (page, scrollToTop = false) {
      let animate = false
      if (page === this.page - 1) {
        this.animatePageShift = 'right'
        animate = true
      } else if (page === this.page + 1) {
        this.animatePageShift = 'left'
        animate = true
      }
      if (animate) {
        setTimeout(() => {
          this.fetchPage(page, scrollToTop)
          // animation is set to 700ms below in <style>
          // keep this at 600ms to prevent display flashing due to rerendering
        }, 600)
      } else {
        this.fetchPage(page, scrollToTop)
      }
    },
    limitChanged (limit) {
      this.$store.commit('dam/setPage', 1)
      this.$store.commit('dam/setLimit', limit)
      this.$store.dispatch('dam/fetchPage')
    },
    showAssetDeleteMoreModal () {
      this.assetDeleteMoreModal = true
    },
    closeAssetDeleteMoreModal () {
      this.assetDeleteMoreModal = false
      this.cancelSelection()
    },
    showAssetBulkMetadataEditModal () {
      this.assetBulkMetadataEditModal = true
    },
    closeAssetBulkMetadataEditModal () {
      this.assetBulkMetadataEditModal = false
      this.cancelSelection()
    },
    showMetadata () {
      this.showMeta = !this.showMeta
    },
    addMedia (assets) {
      setTimeout(() => {
        this.searchAssets()
      }, 3000)
    },
    showDamGenericInfoModal () {
      this.damGenericInfoModal = true
    },
    closeDamGenericInfoModal () {
      this.damGenericInfoModal = false
    },
    cancelSelection () {
      this.selectedAssets = {}
    }
  },
  mounted () {
    this.$store.commit('dam/setIsPdf', this.isPdf)
    this.$store.commit('dam/clearList')
    this.$store.commit('dam/resetImageCache')
    this.$store.commit('dam/setPage', 1)
    this.$store.commit('dam/setLimit', this.LIMIT)
    this.$store.commit('dam/setMaxCreatedAt', '')
    this.searchAssets()
  }
}
</script>

<style lang="scss" scoped>
.dam-button {
  border-radius: rem(4px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  color: #465674;
  background-color: #FFFFFF;
  @include font(700 13px "Roboto");
  height: rem(30px);
  padding-left: 1rem;
  padding-right: 1rem;
  &:hover:not(.dam-button--disabled) {
    border: solid 1px #6599fe;
    color: #6599fe;
  }
  &--active:not(.dam-button--disabled) {
    color: #FFFFFF;
    background-color: #6599FE;
    &:hover {
      background: darken(#6599FE, 10%);
      color: #FFFFFF;
    }
  }
  &--disabled {
    color: lighten(#465674, 20%);
    background-color: lighten(#D1DBE4, 5%);
    cursor: default;
  }
  i {
    padding-right: 0.2rem;
  }
}

.dam-buttons {
  margin-top: 1rem;
  display: flex;
  gap: rem(5px);
  flex-wrap: wrap;
  justify-content: space-between;
}

.dam-buttons,
.dam-filter {
  padding-right: rem(10px);
}

.dam-filter > div {
  padding-left: rem(5px);
  padding-right: rem(5px);
}

.drop-zone {
  display: none;
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 2;
  border: 3px dashed #ccc;
  background: rgba(29, 35, 42, 0.2);
  width: 100%;
  height: 100%;

  p {
    pointer-events: none;
    margin-top: 200px;

    span {
      background: rgba(29, 35, 42, 0.5);
      padding: 20px;
      font-size: 48px;
      color: #fff;
      border-radius: 25px;
    }
  }
}

.drop-zone.active {
  display: block;
}

#show-multi-select * {
  pointer-events: none;
}

.preloader {
  position: absolute;
  z-index:999;
  height: 100vh;
  left: 8vw;
  background: #FFFFFF;
  opacity: 0.5;
}

.selected_images_count {
  @include font(300 18px "Roboto");
  font-style: italic;
  color: #465674;
}

.delete-button {
  padding-bottom: rem(5px);
  height: rem(30px);
}

.dam-pagination {
  padding-right: rem(10px);
}

.pages {
  display: flex;
}
.prev-page {
  flex: 0 0 100%;
  position: relative;
  left: -105%;
}
.current-page {
  flex: 0 0 100%;
  position: relative;
  left: -100%;
}
.next-page {
  flex: 0 0 100%;
  position: relative;
  left: -95%;
}
.next-page-shift-left {
  position: relative;
  animation: next-page-shift-left 700ms;
}
.current-page-shift-left {
  position: relative;
  animation: current-page-shift-left 700ms;
}
.prev-page-shift-right {
  position: relative;
  animation: prev-page-shift-right 700ms;
}
.current-page-shift-right {
  position: relative;
  animation: current-page-shift-right 700ms;
}

@-webkit-keyframes next-page-shift-left {
  0% {
    left: -100%;
  }
  100% {
    left: -200%;
  }
}
@-webkit-keyframes current-page-shift-left {
  0% {
    left: -100%;
  }
  100% {
    left: -205%;
  }
}
@-webkit-keyframes prev-page-shift-right {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
@-webkit-keyframes current-page-shift-right {
  0% {
    left: -100%;
  }
  100% {
    left: 5%;
  }
}
</style>
