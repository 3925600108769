<template>
  <div>
    <div v-if="header" class="form-buttons">
      <CropperRotateButtons
        v-if="!isPdf"
        :asset="asset"
        @post-update="onRotateImage"
      />
      <button
        type="button"
        class="form-button form-button--download"
        @click="downloadAsset(asset)"
        :title="$t('dam.download_original')"
      >
        <IconDownload class="form-button__icon" />
        {{ $t('dam.download_original') }}
      </button>
      <button
        v-if="isPdf"
        class="form-button"
        @click="originalUrlToClipboard(asset)"
      >
        <i class="fas fa-link"></i>
      </button>
    </div>
    <div v-else :class="`asset-info${isPdf ? '-pdf' : ''}`">
      <div v-show="left">
        <a v-if="!callingAPI" :href="isPdf && getOriginalUrl(asset)" target="_blank">
          <PdfVuer v-if="isPdf" :src="getOriginalUrl(asset)" />
          <div
            v-else
            class="asset-info-image"
            :style="'background-image: url(' + previewImage + ')'"
          />
        </a>
      </div>
      <div v-show="right">
        <h4>{{ $t('dam.system_info') }}</h4>
        <table class="table table-striped asset-info-table">
          <tr>
            <th width="150">{{ $t('dam.id') }}</th>
            <td>{{ asset.entityUuid }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.filename') }}</th>
            <td>{{ asset.fileName }}</td>
          </tr>
          <tr>
            <th>{{ $t(`dam.datetime_original${isPdf ? '_pdf' : ''}`) }}</th>
            <td v-if="asset.dateTimeOriginal">
              {{ asset.dateTimeOriginal | prettyDateTime }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('dam.created') }}</th>
            <td>{{ getCreatedValue(asset) }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.upload_source') }}</th>
            <td><span class="label label-success">{{ getUploadSource(asset) }}</span></td>
          </tr>
          <tr v-if="asset.siteLocks && asset.siteLocks[0] !== 'none'">
            <th>{{ $t('dam.restricted_sites') }}</th>
            <td>
              <span
                class="label label-danger m-r-5"
                v-for="(siteName, index) in asset.siteLocks"
                :key="`item-${index}`"
              >
                  {{ getFullSiteTitle(siteName) }}
              </span>
            </td>
          </tr>
          <tr>
            <th>{{ $t('dam.size') }}</th>
            <td>{{ getAssetDimensionsAndSize(asset) }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.published') }}</th>
            <td>{{ $t(`${asset.wasPublished ? 'yes' : 'no'}`).toUpperCase() }}</td>
          </tr>
          <tr v-if="!isImageAsset">
            <th>{{ $t('dam.content') }}</th>
            <td v-if="content">
              <textarea v-model="content" disabled class="asset-content"></textarea>
            </td>
            <td v-else>
              <button
                target="_blank"
                class="btn btn-sm btn-danger"
                @click="loadContent(asset)"
              >
                <i class="fas fa-download"></i> <span class="hidden-md-down">{{ $t('dam.content_load') }}</span>
              </button>
            </td>
          </tr>
        </table>

        <h4 class="m-t-20">{{ $t('dam.metadata') }}</h4>
        <table class="table table-striped asset-info-table">
          <tr v-if="asset.headline">
            <th width="150">{{ $t('dam.headline') }}:</th>
            <td>{{ asset.headline }}</td>
          </tr>
          <tr v-if="asset.description">
            <th>{{ $t(`dam.description${isPdf ? '_short' : ''}`) }}</th>
            <td>{{ asset.description }}</td>
          </tr>
          <tr v-if="asset.imageAltText">
            <th>{{ $t('dam.imageAltText') }}</th>
            <td>{{ asset.imageAltText }}</td>
          </tr>
          <tr v-if="asset.caption">
            <th>{{ $t('dam.caption') }}</th>
            <td>{{ asset.caption }}</td>
          </tr>
          <tr v-if="asset.author">
            <th>{{ $t('dam.author') }}</th>
            <td>{{ asset.author.toString() }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.keywords') }}</th>
            <td>
              <span
                class="label label-success m-r-5 metadata-keyword"
                v-for="(keyword, index) in (asset.keywords ? asset.keywords.split(',') : [])"
                :key="`item-${index}`"
                @click="filterByKeyword(keyword)"
              >
                {{ keyword }}
              </span>
            </td>
          </tr>
          <tr v-if="asset.captionWriter">
            <th>{{ $t('dam.caption_writer') }}</th>
            <td>{{ asset.captionWriter }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.city">
            <th>{{ $t('dam.city') }}</th>
            <td>{{ asset.city }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.country">
            <th>{{ $t('dam.country') }}</th>
            <td>{{ asset.country }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.countryCode">
            <th>{{ $t('dam.country_code') }}</th>
            <td>{{ asset.countryCode }}</td>
          </tr>
          <tr v-if="asset.credit">
            <th>{{ $t('dam.credit') }}</th>
            <td>{{ asset.credit }}</td>
          </tr>
          <tr v-if="asset.categoryCode">
            <th>{{ $t('dam.category') }}</th>
            <td class="label label-success">{{ pdfCategory }}</td>
          </tr>
          <tr v-if="asset.locationShow ">
            <th>{{ $t('dam.location_show') }}</th>
            <td>{{ asset.locationShow }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.personsInImage && asset.personsInImage.length > 0">
            <th>{{ $t('dam.person_in_image') }}</th>
            <td>
              <template v-for="(person, index) in asset.personsInImage">
                <span :key="`item-${index}`">{{ person }}</span>
              </template>
            </td>
          </tr>
          <tr v-if="asset.rights">
            <th>{{ $t('dam.rights') }}</th>
            <td>{{ asset.rights }}</td>
          </tr>
          <tr v-if="asset.source">
            <th>{{ $t('dam.source') }}</th>
            <td>{{ asset.source }}</td>
          </tr>
        </table>
        <div>
          <h4 class="m-t-20">{{ $t('media.actions') }}</h4>
          <button
            v-if="isImageAsset === false"
            class="btn btn-sm btn-inverse"
            @click="originalUrlToClipboard(asset)"
          >
            <i class="fas fa-link"></i>
          </button>
          <button
            target="_blank"
            class="btn btn-sm btn-inverse"
            @click="downloadAsset(asset)"
          >
            <i class="fas fa-download"></i> <span class="hidden-md-down">{{ $t('media.button_download') }}</span>
          </button>
          <a
            v-if="isImageAsset === false"
            :href="getOriginalUrl(asset)"
            target="_blank"
            class="btn btn-sm btn-inverse"
          >
            <i class="fas fa-eye"></i> <span class="hidden-md-down">{{ $t('media.button_open_preview') }}</span>
          </a>
        </div>
        <div>
          <h4 class="m-t-20">{{ $t('dam.article_reference') }}</h4>
          <app-asset-article-reference :asset="asset" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PdfVuer from 'pdfvuer'
import DamPdfService from '@/services/dam/DamPdfService'
import MediaMixin from '../../mixins/Media'
import DamApi from '../../../api/dam'
import DamPdfCategoryMixin from '../../mixins/valueObject/DamPdfCategoryMixin'
import NotifyService from '../../../services/NotifyService'
import AssetArticleReference from '../AssetArticleReference'
import IconDownload from '@/assets/img/svg/download.svg?inline'
import { mapGetters, mapState } from 'vuex'
import { AVAILABLE_SOURCE_MAP } from '@/model/ValueObject/DamUploadSources'
import DamService from '@/services/dam/DamService'
import CropperRotateButtons from '@/components/shared/CropperRotateButtons'

export default {
  name: 'AssetInfoForm',
  mixins: [MediaMixin, DamPdfCategoryMixin],
  props: {
    asset: {
      type: Object,
      required: true
    },
    header: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CropperRotateButtons,
    appAssetArticleReference: AssetArticleReference,
    IconDownload,
    PdfVuer
  },
  data () {
    return {
      content: ''
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('dam', [
      'isPdf'
    ]),
    pdfCategory () {
      return this.damCategories.find((category) => category.id === +this.asset.categoryCode)?.title
    },
    getAllSites () {
      return this.$store.getters['site/all']
    },
    previewImage () {
      return DamService.getDamImagePathForAsset(this.asset, { dynamic: true })
    },
    isImageAsset () {
      return !this.isPdf
    }
  },
  methods: {
    prettyDate (dateTime) {
      return dateTime ? this.$options.filters.prettyDateTime(dateTime) : ''
    },
    getCreatedValue (asset) {
      return `${this.prettyDate(asset.createdAt)}, ${this.$store.getters['beUser/userNameById'](asset.createdBy)}`
    },
    getUploadSource (asset) {
      return AVAILABLE_SOURCE_MAP[asset.uploadSourceCode]
    },
    onRotateImage (updatedAsset) {
      this.$emit('post-update', updatedAsset)
    },
    filterByKeyword (keyword) {
      const filter = this.$store.getters['dam/filter']
      filter.keywords = keyword
      this.$store.commit('dam/setFilter', filter)
      this.$store.dispatch('dam/fetchPage')
      this.$store.commit('dam/toggleAdvancedFilter', true)
      this.$emit('close')
    },
    async loadContent (asset) {
      const result = await DamApi().get('/pdf/content/' + asset.entityUuid)
      this.content = result.data
    },
    async originalUrlToClipboard (asset) {
      await navigator.clipboard.writeText(this.getOriginalUrl(asset))
      NotifyService.setInfoMessage(this.$i18n.t('notify.url_was_copied'))
    },
    getOriginalUrl (asset) {
      return DamPdfService.getPdfPath(asset)
    },
    getAssetSize (asset) {
      return asset.size ? Math.round(asset.size * 1000 / 1048576) / 1000 + ' MB' : ''
    },
    getAssetDimensions (asset) {
      return asset.width + ' x ' + asset.height + ' px'
    },
    getAssetDimensionsAndSize (asset) {
      if (!this.isImageAsset) {
        return this.getAssetSize(asset)
      }
      return `${this.getAssetDimensions(asset)}, ${this.getAssetSize(asset)}`
    },
    getFullSiteTitle (siteName) {
      return this.getAllSites.find(site => site.name === siteName)?.title ?? ''
    }
  }
}
</script>

<style lang="scss" scoped>
.asset-info-pdf {
  overflow: scroll;
  height: rem(700px);
  width: rem(670px);
}
.asset-info-image {
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  border: none;
  cursor: pointer;
  position: relative;
  margin-top: 30px;
}

.asset-info-table {
  font-size: 14px;

  span {
    font-size: 13px;
  }
}

.asset-content {
  border: 0;
  background: none;
  width: 100%;
  height: 20ex;
}

.form-buttons {
  display: flex;
  gap: rem(4px);
}
.form-button {
  @include font(700 13px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #465674;
  height: rem(30px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(5px);
  &:hover {
    background-color: lighten(#D1DBE4, 5%);
  }
  &--download {
    width: rem(150px);
  }
  &__icon {
    @include size(14px);
    fill: #9DAAC4;
  }
}
.metadata-keyword {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    background-color: darken(#26c6da, 20%);
  }
}
</style>
