<template>
  <app-modal
    :title="$t('dam.delete_more_modal_header', {recordTypeName: isImagePage() ? 'fotky' : 'dokumenty'})"
    modal-size="modal-lg"
    closeOnClickSelf
    @close="close"
    data-test="dam_delete_modal"
  >
    <!-- Body -->
    <template slot="body">
      <p v-html="$t('dam.delete_more_modal_body', {recordTypeName: isImagePage() ? 'fotky' : 'dokumenty' })"></p>
      <h4 class="m-t-20">{{ $t('dam.article_reference') }}</h4>
      <div v-for="asset in assets" v-bind:key="asset.entityUuid">
        <div :style="'float: left; margin-right: 15px; margin-bottom: 15px'">
          <img :src="getReferenceAssetPreviewUrl(asset)" :width="'100px'" />
        </div>
        <div :style="'float: left'">
          <small class="cropText">
            {{ $t('dam.id') }}: <span class="text-muted" :title="asset.entityUuid">{{ asset.entityUuid }}</span>
          </small><br>
          <small class="cropText" v-if="asset.headline">
            {{ $t('dam.headline') }}: <span class="text-muted"
                                            :title="asset.headline">{{ asset.headline }}</span>
          </small>
          <small class="cropText" v-else>
            {{ $t('dam.description') }}:
            <span class="text-muted" :title="asset.description">{{ asset.description }}</span>
          </small><br>
          <small class="cropText">
            {{ $t('dam.author') }}: <span class="text-muted"
                                           :title="asset.author">{{ asset.author }}</span>
          </small>
        </div>
        <div :style="'clear: both'">
          <app-asset-article-reference :asset="asset" />
        </div>
      </div>
    </template>

    <!-- Footer -->
    <template slot="footer">
      <button
        type="button"
        class="btn btn-inverse router-link-active"
        @click="close"
        data-test="hp_item_delete_cancel"
      >
        {{ $t('dam.delete_modal_close') }}
      </button>
      <button
        v-if="deleting"
        class="btn btn-inverse"
        disabled
      >
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ $t('notify.loading') }}
      </button>
      <button
        v-else
        type="button"
        class="btn btn-danger"
        @click="deleteRecords"
        data-test="hp_item_delete_confirm"
      >
        {{ $t('dam.delete_modal_yes') }}
      </button>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import NotifyService from '../../services/NotifyService'
import MediaMixin from '../mixins/Media'
import AssetArticleReference from './AssetArticleReference'

export default {
  name: 'AssetDeleteModal',
  mixins: [MediaMixin],
  props: {
    assets: {
      type: Array
    }
  },
  components: {
    appModal: Modal,
    appAssetArticleReference: AssetArticleReference
  },
  data () {
    return {
      deleting: false
    }
  },
  methods: {
    isImagePage () {
      return this.$route.path === '/eagle-dam/image'
    },
    deleteRecords () {
      this.deleting = true
      this.$store.dispatch('dam/deleteRecords', this.assets.map(asset => asset.entityUuid))
        .then(() => {
          for (const asset of this.assets) {
            if (this.$store.getters['dam/error']) {
              NotifyService.setErrorMessage(asset.entityUuid + ': ' + this.$t('notify.record_not_deleted'))
              console.log(asset)
            } else {
              NotifyService.setSuccessMessage(asset.entityUuid + ': ' + this.$t('notify.record_was_deleted'))
            }
          }
          this.deleting = false
          this.$emit('post-delete')
          this.$emit('close')
        })
        .catch(error => console.log(error))
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.cropText {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
}
</style>
