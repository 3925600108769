<template>
  <div class="media-gallery" id="media-gallery">
    <div
      v-for="(media, index) in gallery"
      :key="`item-${media.id}-${index}`"
      :class="{ 'item': true, 'item--small-paddings': smallPaddings }"
    >
      <a>
        <img
          :src="getImagePath(media)"
          :title="media.imageCaption"
          width="100%"
          height="auto"
        />
        <!-- @pointerdown.stop is used to not select and not drag the element when clicking on the buttons -->
        <i
          v-if="!disabled"
          class="fas fa-edit media-edit"
          data-test="media_editMedia"
          @click="showMediaEditModal(media, index)"
          @pointerdown.stop
        >
        </i>
        <i
          v-if="!disabled"
          class="fas fa-download media-download"
          :title="$t('media.button_download')"
          @click="downloadMedia(media)"
          @pointerdown.stop
        >
        </i>
        <i
          v-if="!disabled"
          class="fa fa-trash del media-delete"
          data-test="media_removeMedia"
          @click="removeMedia(media)"
          @pointerdown.stop
        >
        </i>
        <i
          v-show="!disabled && showHeroButton"
          class="fas fa-images media-set-hero-image"
          data-test="media_setHeroImage"
          @click="showArticleSetHeroImageModal(media)"
          @pointerdown.stop
        >
          <span> {{ $t('article.hero_image') }}</span>
        </i>
        <i
          v-show="!disabled && showBodyButton"
          class="fas fa-download fa-rotate-180 media-add-to-bodytext"
          data-test="media_addToBodytext"
          @click="showAddMediaToBodyTextModal(media)"
          @pointerdown.stop
          :title="$t('media.button_add_media_to_bodytext')"
        >
        </i>
      </a>
      <div v-if="showInfo" class="media-info">
        <div>
          <span class="media-info__label">{{ $t('media.author') }}:</span>
          <span class="media-info__value">{{ media.imageAttribution }}</span>
        </div>
        <div>
          <span class="media-info__label">{{ $t('media.caption') }}:</span>
          <span class="media-info__value">{{ media.imageCaption }}</span>
        </div>
      </div>
    </div>
    <app-media-edit-modal
      v-if="mediaEditModal"
      :selectedMedia="mediaToEdit"
      :site="site"
      :media-usage-type="mediaUsageType"
      :insert-button-label="insertButtonLabel"
      @add-media="addMedia"
      @close="closeMediaEditModal"
    >
    </app-media-edit-modal>
  </div>
</template>
<script>
import Sortable from 'sortablejs'
import MediaEditModal from '../shared/MediaEditModal'
import MediaMixin from '../mixins/Media'
import DamService, { STATIC_CROP_320_240 } from '@/services/dam/DamService'
import {
  MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK,
  MEDIA_USAGE_TYPE_ARTICLE_HERO,
  MEDIA_USAGE_TYPE_GALLERY,
  MEDIA_USAGE_TYPE_ARTICLE_GALLERY
} from '@/model/ValueObject/MediaUsageTypes'

export default {
  name: 'ArticleGallery',
  mixins: [MediaMixin],
  props: {
    gallery: {
      type: Array
    },
    id: {
      type: String,
      default: ''
    },
    site: {
      type: [Number, Object],
      default: null
    },
    showHeroButton: {
      type: Boolean,
      default: true
    },
    showBodyButton: {
      type: Boolean,
      default: true
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    parentStoreName: {
      type: String,
      default: 'article' // 'gallery', 'recipe', 'dam'
    },
    smallPaddings: {
      type: Boolean,
      default: false
    },
    multiDrag: {
      type: Boolean,
      default: false
    },
    /**
     * See MediaUsageTypes.js
     */
    galleryMediaUsageType: {
      type: String,
      default: MEDIA_USAGE_TYPE_GALLERY // MEDIA_USAGE_TYPE_GALLERY | MEDIA_USAGE_TYPE_ARTICLE_GALLERY
    }
  },
  data () {
    return {
      mediaEditModal: false,
      mediaToEdit: null,
      mediaToEditIndex: null,
      mediaToRemove: null,
      sortable: null,
      mediaUsageType: null, // MEDIA_USAGE_TYPE_GALLERY | MEDIA_USAGE_TYPE_ARTICLE_GALLERY | MEDIA_USAGE_TYPE_ARTICLE_HERO | MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK
      insertButtonLabel: null
    }
  },
  components: {
    appMediaEditModal: MediaEditModal
  },
  watch: {
    multiDrag () {
      this.setupDraggableGallery()
    }
  },
  methods: {
    showMediaEditModal (media, index) {
      this.mediaUsageType = this.galleryMediaUsageType
      this.mediaToEdit = [media]
      this.mediaToEditIndex = index
      this.insertButtonLabel = this.$t('modal.done')
      this.mediaEditModal = true
    },
    closeMediaEditModal () {
      this.mediaEditModal = false
    },
    showArticleSetHeroImageModal (media) {
      this.cloneMediaAndShowEditModal(media, MEDIA_USAGE_TYPE_ARTICLE_HERO)
    },
    showAddMediaToBodyTextModal (media) {
      this.mediaToRemove = media
      this.cloneMediaAndShowEditModal(media, MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK)
    },
    cloneMediaAndShowEditModal (media, mediaUsageType) {
      this.mediaUsageType = mediaUsageType
      const newMedia = this._.cloneDeep(media)
      this.mediaToEdit = [newMedia]
      this.insertButtonLabel = null
      this.mediaEditModal = true
    },
    addMedia (medias) {
      if ([MEDIA_USAGE_TYPE_GALLERY, MEDIA_USAGE_TYPE_ARTICLE_GALLERY].includes(this.mediaUsageType)) {
        this.replaceEditedMedia(medias[0])
      } else if (this.mediaUsageType === MEDIA_USAGE_TYPE_ARTICLE_HERO) {
        this.setHeroImage(medias[0])
      } else if (this.mediaUsageType === MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK) {
        this.addMediaToBodytext(medias[0])
      } else {
        throw Error('Unsupported "mediaUsageType"!')
      }
    },
    replaceEditedMedia (media) {
      this.gallery[this.mediaToEditIndex] = media
    },
    removeMedia (media) {
      this.$store.commit(`${this.parentStoreName}/removeMediaFromGallery`, media)
    },
    setHeroImage (media) {
      this.$emit('set-hero-image', [media])
    },
    getImagePath (mediaOrAsset) {
      if (this.parentStoreName === 'dam') {
        return DamService.getDamImagePathForAsset(mediaOrAsset)
      }
      // media in Article-Gallery can still have an old mediaUsageType 'gallery' if the media was added from an existing Gallery
      //  so the best 'mediaUsageType' here is from 'mediaOrAsset.mediaUsageType'
      const usageType = mediaOrAsset.mediaUsageType ?? this.galleryMediaUsageType
      return DamService.getDamImagePathForMedia(mediaOrAsset, usageType, STATIC_CROP_320_240)
    },
    addMediaToBodytext (media) {
      this.$store.commit('article/removeMediaFromGallery', this.mediaToRemove)
      this.$emit('add-media-to-bodytext', [media])
    },
    /**
     * This waits for the gallery to be loaded and then sets drag&drop with 'Sortable' to the gallery component.
     * <p>
     * Note: Do NOT use 'vuedraggable' package here as it doesn't work properly with 'multiDrag' option.
     * </p>
     */
    setupDraggableGallery () {
      this.$nextTick(() => {
        if (this.sortable) {
          this.sortable.destroy()
        }
        const options = {
          multiDrag: this.multiDrag, // MultiDrag plugin is mounted in main.js
          selectedClass: 'item--selected',
          animation: 150
        }
        this.sortable = Sortable.create(document.getElementById('media-gallery'), options)
        this.sortable.option('onEnd', evt => {
          evt.items.forEach(item => {
            Sortable.utils.deselect(item)
          })
        })
        this.sortable.option('onUpdate', newValue => {
          let reorderedGallery = [...this.gallery]
          const oldIndices = newValue.oldIndicies?.length > 0 ? newValue.oldIndicies.map(i => i.index) : [newValue.oldIndex]
          const newIndex = newValue.newIndicies?.length > 0 ? newValue.newIndicies[0].index : newValue.newIndex
          const movedItems = []

          // 1. store each moved item with its new index and replace it with 'undefined'
          //  note: do NOT use splice() here as each index in 'oldIndices' needs to be applied to the original array
          oldIndices.forEach(oldIndex => {
            movedItems.push(reorderedGallery[oldIndex])
            reorderedGallery[oldIndex] = undefined
          })
          // 2. after all the moved items are stored, remove them from the array
          reorderedGallery = reorderedGallery.filter(media => media !== undefined)
          // 3. and add the moved items to the new index
          reorderedGallery.splice(newIndex, 0, ...movedItems)
          this.$store.commit(`${this.parentStoreName}/updateGallery`, reorderedGallery)
        })
      })
    }
  },
  created () {
    this.setupDraggableGallery()
  }
}
</script>

<style lang="scss">
.media-gallery {

  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px -3px 0;
  padding: 0;

  a {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
  }

  img {
    object-fit: cover;
    cursor: move;
  }

  i {
    cursor: pointer;
  }

  .item {
    width: 20%;
    box-sizing: border-box;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
    position: relative;
    cursor: pointer;
    padding: rem(10px);
    margin: rem(6px);
    background-color: #FFFFFF;
    &--selected {
      border: 3px solid #6599fe
    }
    &--small-paddings {
      padding: rem(5px);
      margin: rem(0px);
    }
  }

  @media (max-width: 1500px) {
    .item {
      width: 23%;
    }
  }

  @media (max-width: 1260px) {
    .item {
      width: 31%;
    }
  }

  @media (max-width: 600px) {
    .item {
      width: 48%;
    }
  }

  .media-edit,
  .media-crop,
  .media-download,
  .media-delete,
  .media-set-hero-image,
  .media-add-to-bodytext {
    position: absolute;
    z-index: 1;
    padding: 10px;
    display: none;
    color: #fff;
  }

  .media-edit,
  .media-crop,
  .media-download,
  .media-delete {
    top: 0;
  }

  .media-edit {
    left: 0;
    background: #2f3d4a;
  }

  .media-crop {
    left: 40px;
    background: #2f3d4a;
  }

  .media-download {
    left: 40px;
    background: #2f3d4a;
  }

  .media-delete {
    right: 0;
    background: #dc3545;
  }

  .media-set-hero-image {
    left: 0;
    bottom: 0px;
    font-size: 10px;
    background: #dc3545;

    span {
      font-family: Arial;
      font-size: 10px;
    }
  }

  .media-add-to-bodytext {
    bottom: 0px;
    right: 0px;
    background: #2f3d4a;
  }

  .item a:hover {
    .media-edit,
    .media-crop,
    .media-download,
    .media-delete,
    .media-set-hero-image,
    .media-add-to-bodytext {
      display: block;
    }
  }

  .media-metadata {
    float: left;
    margin-left: 10px;
    width: 800px;
  }

  .media-info {
    word-break: break-all;
    &__label {
      @include font(400 12px "Roboto");
      color: #8A96AC;
    }
    &__value {
      @include font(500 12px "Roboto");
      color: #465674;
    }
  }

}
</style>
