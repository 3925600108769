<template>
  <div class="form-buttons">
    <button
      type="button"
      class="form-button"
      @click="rotate(270)"
      :title="$t('dam.rotate_minus_90')"
    >
      <IconRotateLeft class="form-button__icon" />
      {{ $t('dam.rotate_minus_90') }}
    </button>
    <button
      type="button"
      class="form-button"
      @click="rotate(90)"
      :title="$t('dam.rotate_plus_90')"
    >
      <IconRotateRight class="form-button__icon" />
      {{ $t('dam.rotate_plus_90') }}
    </button>
    <button
      v-if="showFaceDetectButton"
      type="button"
      class="form-button"
      @click="faceDetect()"
    >
      <IconFaceDetection class="form-button__icon" />
      {{ $t('dam.crop_modal_face_detect') }}
    </button>
  </div>
</template>

<script>
import NotifyService from '@/services/NotifyService'
import MediaMixin from '@/components/mixins/Media'
import CropMixin from '@/components/mixins/Crop'
import DamApi from '@/api/dam'
import IconRotateLeft from '@/assets/img/svg/rotate-left.svg?inline'
import IconRotateRight from '@/assets/img/svg/rotate-right.svg?inline'
import IconFaceDetection from '@/assets/img/svg/face-detection.svg?inline'
import DamService from '@/services/dam/DamService'

require('tracking')
require('tracking/build/data/face-min')

export default {
  name: 'CropperRotateButtons',
  mixins: [MediaMixin, CropMixin],
  props: {
    asset: {
      type: Object,
      required: true
    },
    showFaceDetectButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    assetPath () {
      return DamService.getDamImagePathForAsset(this.asset, { dynamic: true })
    }
  },
  components: {
    IconRotateLeft,
    IconRotateRight,
    IconFaceDetection
  },
  methods: {
    rotate (angle) {
      this.$store.commit('TOGGLE_LOADING')
      DamApi().patch(`/image/${this.asset.entityUuid}/rotate/${angle}`)
        .then(async (response) => {
          const updatedAsset = response.data.imageData
          this.$emit('post-update', updatedAsset)
        })
        .catch(error => {
          console.error(error)
          NotifyService.setErrorMessage(this.$t('dam.rotate_error'))
        })
        .finally(() => this.$store.commit('TOGGLE_LOADING'))
    }
  }

}
</script>

<style scoped lang="scss">
.form-buttons {
  display: flex;
  gap: rem(4px);
  float: left;
}
.form-button {
  @include font(700 13px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #465674;
  height: rem(30px);
  width: rem(130px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(5px);
  &:hover {
    background-color: lighten(#D1DBE4, 5%);
  }
  &__icon {
    @include size(14px);
    fill: #9DAAC4;
  }
  &--save {
    width: rem(170px);
    color: #FFFFFF;
    background-color: #6599FE;
    &:hover {
      background-color: lighten(#6599FE, 5%);
    }
  }
}
</style>
