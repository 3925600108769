<template>
  <span>
    {{ authorName }}<template v-if="index < (article.authors.length - 1)">,</template>
  </span>
</template>

<script>
import CoreApi from '@/api/core'
export default {
  name: 'TableArticleListAuthor',
  props: {
    index: {
      type: Number,
      required: true
    },
    author: {
      type: Number,
      required: true
    },
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      authorName: ''
    }
  },
  methods: {
    async authorNameById (id) {
      await CoreApi().get(`/author/${id}`)
        .then(res => {
          const author = res.data
          this.authorName = this.$store.getters['author/getAuthorCustomName'](author)
        })
        .catch(error => console.error(error))
    }
  },
  created () {
    this.authorNameById(this.author)
  }
}
</script>
